import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const SoapBoat = () => (
  <Layout
    title="Soap Boat - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Soap Boat</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Soap Boat</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../liquid-fireworks" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="e51ade64-d24a-45ff-8058-89011f2f9b54"
                className="de-vid"
              ></Video>
              <Link to="../how-do-we-breathe" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Join 3M Researcher Vasav Shani as he introduces you to the science
              of surface tension. Not only is it only important for many
              engineering and earth science processes, it also makes blowing
              bubbles possible.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              Much of what we now know about surface tension was discovered by
              the scientist, Agnes Pockels, who was an early pioneer of "surface
              science." She did experiments in the sink of her own kitchen and
              made many important observations that influenced how we study
              liquids. Surface tension is not only important for many
              engineering processes - it is also important to the health of the
              planet, as it allows for the formation of bubbles, which help
              distribute organic material throughout the layers of the ocean.
              Today we’re going to do an experiment that will help us better
              understand how surface tension can be altered with soap and water.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Surface Tension</li>
              <li>Forces</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Bread clip or notecard cut into a similar shape</li>
              <li>Scissors</li>
              <li>Bowl</li>
              <li>Water</li>
              <li>Liquid soap (dish soap works best)</li>
              <li>Cotton swab or toothpick (optional)</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Trim the bread clip into the shape of a boat’s hull - make sure
                the back of the boat is where the notch in the clip is. You may
                want an adult to help you with this step. If you don’t have a
                bread clip, cut out a boat shape (like a pentagon) from a
                notecard and make a notch in the back of the boat.{' '}
              </li>
              <li>Fill a bowl with water from the tap.</li>
              <li>
                Make a prediction: What will happen if you put soap into the
                notch on the back of the boat?
              </li>
              <li>
                Place the boat in the water, close to the edge of the bowl
              </li>
              <li>
                Use your finger, a toothpick or a cotton swab to dab some dish
                soap in the notch in the back of the boat. See what happens!
              </li>
              <li>
                Don't forget to clean up when you are done. The water can be
                poured down the drain, and the bowl can be rinsed and put away.
                You can throw away the boat and cotton swab or toothpicks. Put
                any other materials back where you found them.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              The boat should zoom forward in the water. This is because liquids
              always move from low surface tension to high surface tension; this
              is called the Marangoni effect. Soap has a much lower surface
              tension than water, so it pushes out from the notch in the back of
              the boat. This force pushes the boat forward, similar to a rocket
              pushing fuel out of the tank to propel itself upwards.
            </p>
            <p>
              This experiment uses a very small boat of a very particular shape.
              What might happen with a different size or different shape? Can
              the boat carry any kind of a load? Can you put weight on it and
              have it still move? How much? Instead of putting weight on the
              boat, is there a way to turn it into a tug boat, pull something
              behind it, and still have it work? How does making any of these
              changes affect the performance of the boat?
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A - Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        many of them can be either solid or liquid (or gas).
                      </li>
                      <li>
                        <em>2-PS1-2.</em> Different properties are suited to
                        different purposes.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em> Matter of any type can be subdivided
                        into particles that are too small to see but even then,
                        the matter still exists and can be detected by other
                        means.
                      </li>
                      <li>
                        <em>5-PS1-3.</em> Measurements of a variety of
                        properties can be used to identify materials.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em> Substances are made of different
                        types of atoms, which combine with one another in
                        various ways. Atoms form molecules that range in size
                        from two to thousands of atoms.
                      </li>
                      <li>
                        <em>MS-PS1-4.</em> Gases and liquids are made of
                        molecules or inert atoms that are moving about relative
                        to each other. In a liquid, the molecules are constantly
                        in contact with others.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-1.</em> Each atom has a charged substructure
                        consisting of a nucleus, which is made of protons and
                        neutrons surrounded by electrons.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> The structure and interactions of
                        matter at the bulk scale are determined by electrical
                        forces within and between atoms.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS2.A - Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS2-2.</em> Pushing or pulling on an object can
                        change the speed or direction of its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Each force acts on one particular
                        object and has both strength and a direction.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-1.</em> For any pair of interacting objects,
                        the force exerted by the first object on the second
                        object is equal in strength to the force that the second
                        object exerts on the first, but in the opposite
                        direction.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS2.B - Types of Interaction
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> When objects touch or collide, they
                        push on one another and can change motion.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Objects in contact exert forces on
                        each other.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-6.</em> Attraction and repulsion between
                        electric charges at the atomic scale explain the
                        structure, properties, and transformations of matter, as
                        well as the contact forces between material objects.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="SoapBoat-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Vasav Shani</h3>
                <h4>Researcher, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="LiquidFireworks-Thumbnail.png" />
              <div className="card__content">
                <h3>Liquid Fireworks</h3>
                <p>
                  Join 3M scientist Jeff Payne as he uses nothing more than
                  milk, dish soap, and a few other kitchen supplies to get the
                  amazing effects of fireworks without using any fire at all.
                </p>
              </div>
              <Link
                to="../liquid-fireworks"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="HowDoWeBreathe-Thumbnail.png" />
              <div className="card__content">
                <h3>How Do We Breathe?</h3>
                <h4>How do our lungs work?</h4>
                <p>
                  Follow along as special guest Dakota Dozier, an offensive
                  lineman for the Minnesota Vikings, makes a model to show how
                  air flows in and out of the lungs with ease.
                </p>
              </div>
              <Link
                to="../how-do-we-breathe"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Bernoulli Balance */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="BernoulliBalance-Thumbnail.png" />
              <div className="card__content">
                <h3>Bernoulli Balance</h3>
                <h4>
                  A jetliner can weigh over 300 tons, so why doesn’t it just
                  drop out of the air?
                </h4>
                <p>
                  3M’s SVP of Corporate Affairs, Denise Rutherford explains the
                  Bernoulli principle and how without it, planes (and birds)
                  couldn’t fly.
                </p>
              </div>
              <Link
                to="../bernoulli-balance"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default SoapBoat;
